<template>
    <div class="mezzanines">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Mezzanines y Entrepisos',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabricantes de estantería para almacenamiento manual' },
			{ name: 'keywords', content:'estantería metálica, mezzanines, entrepisos modulares' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Mezzanines y Entrepisos'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabricantes de mezzanines y entrepisos modulares'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Mezzanines y Entrepisos'},
			{name: 'twitter:description', content: 'Fabricantes de mezzanines y entrepisos modulares' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Mezzanines y Entrepisos'},
			{itemprop: 'description', content: 'Fabricantes de mezzanines y entrepisos modulares' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Entrepisos y Mezzanines',
                types: [
                    // { text: 'Todos', value: 'todos' },
                    { text: 'Modular M-300', value: 'modular-m300' },
                    { text: 'Estantería con entrepisos', value: 'estanteria-con-entrepisos' }
                ],
                products: [
                    {
                        name: 'Mezzanine Modular M-300',
                        description: `
                            <p>
                                Los mezzanines modulares están diseñados
                                para optimizar los espacios del área de una bodega habilitando ambientes
                                en el primero y segundo piso. Su capacidad es de 700 kg por metro
                                cuadrado. Esta compuesta por postes, vigas y entrepisos metálicos
                                antideslizantes en alfajor.
                            </p>
                            <p>
                                Son estanterías prácticas y totalmente desmontables, se pueden reubicar
                                fácilmente y son muy convenientes cuando no se cuenta con locales
                                propios y se requiere de áreas seguras protegidas de inundaciones.
                            </p>
                        `,
                        url_image: 'estanterias-metalicas/entrepisos/productos/mezzanine-modular-M-300/modular_2.jpg',
                        slides: [
                            'estanterias-metalicas/entrepisos/productos/mezzanine-modular-M-300/modular_1.jpg',
                            'estanterias-metalicas/entrepisos/productos/mezzanine-modular-M-300/modular_2.jpg',
                            'estanterias-metalicas/entrepisos/productos/mezzanine-modular-M-300/modular_3.jpg'
                        ],
                        price: '',
                        tags: ['modular-m300']
                    },
                    {
                        name: 'Estantería con entrepisos',
                        description: `
                            <p>
                                La estructura esta hecha de vigas y columnas
                                que se enganchan con un exclusivo sistema sin tornillos a través de planos
                                inclinados.
                            </p>
                            <p>
                                Ofrece un aprovechamiento del espacio vertical, posibilitando duplicar o
                                triplicar las superficies sin necesidad de obras civiles. El diseño y la
                                fabricación de los mezzanines entrepisos se realiza dependiendo la
                                necesidad y los espacios del cliente.
                            </p>
                            <p>
                                El manejo de la mercancía se puede realizar por medio de montacargas,
                                ascensor o manualmente por escaleras a varios niveles. También posee
                                pisos metálicos antideslizantes en alfajor o lamina galvanizada, permitiendo
                                le fácil desplazamiento de carritos auxiliares en sus pasillos.
                            </p>
                        `,
                        url_image: 'estanterias-metalicas/entrepisos/productos/estanteria-con-entrepisos/mezzanine_entrepisos_4.jpg',
                        slides: [
                            'estanterias-metalicas/entrepisos/productos/estanteria-con-entrepisos/mezzanine_entrepisos_4.jpg',
                            'estanterias-metalicas/entrepisos/productos/estanteria-con-entrepisos/mezzanine_entrepisos_2.jpg',
                            'estanterias-metalicas/entrepisos/productos/estanteria-con-entrepisos/mezzanine_entrepisos_3.jpg'
                        ],
                        price: '',
                        tags: ['estanteria-con-entrepisos']
                    }
                ],
            }
        }
    }
}
</script>